<script setup lang="ts">
import { useField } from "vee-validate";

interface Props {
  modelValue?: string | number;
  label?: string;
  type?: string;
  name: string;
  placeholder?: string;
  icon?: string;
  validateAs?: string;
  disabled?: boolean;
  onInput?: boolean;
  inputKey?: string | number;
  autofocus?: boolean;
  autocomplete?: string;
}

interface Emit {
  (event: "update:modelValue", value: string): void;
}

defineEmits<Emit>();
const props = withDefaults(defineProps<Props>(), {
  type: "text",
  autocomplete: "off",
});

const input = ref();
const { value, errorMessage } = useField<string>(() => props.name, props.validateAs, {
  syncVModel: true,
});

onMounted(() => {
  if (props.autofocus) input.value.focus();
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
  <div v-auto-animate class="group" :class="{ 'opacity-50 select-none pointer-events-none cursor-default': disabled }">
    <label v-if="label" :for="name" class="px-1 text-xs group-focus-within:text-primary">{{ label }}</label>
    <div class="relative">
      <div v-if="icon" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <Icon :name="icon" class="h-4 w-4 text-medium-gray group-focus-within:text-primary" aria-hidden="true" />
      </div>
      <input
        :id="name"
        ref="input"
        :key="inputKey"
        v-model.lazy.trim="value"
        :type="type"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        class="h-form-input"
        :class="[icon ? 'pl-10' : 'pl-2', { 'border-danger': errorMessage }]"
      />
    </div>
    <InputError v-if="errorMessage">{{ errorMessage }}</InputError>
  </div>
</template>
